<template>
    <div class="home-wrap">
        <div class="home-main-wrap">
            <div class="home-header-wrap">
                <van-image class="avatar" round :src="getAvatar(memberInfo.avatarNew)" />
                <van-image class="logo" :src="require('@siteResources/logo/home_logo.png')" />
                <div class="right-box">
                  <a @click="setShowServicePopup(true)" target="_blank"><van-icon :name="themeImgs.commonServiceIcon" /></a>
                  <van-icon class="msg" :name="themeImgs.homeMsgIcon" @click="handleOpenMsgUrl" />
                </div>
            </div>
            <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
                <van-swipe-item v-for="(item,index) in bannerData" :key="index">
                    <van-image class="avatar" v-if="!item.url" :src="item.pic" />
                    <a v-else-if="item.url_type === 2" @click="goTobanner(item)" target="_blank" rel="noopener noreferrer">
                        <van-image class="avatar" :src="item.pic" />
                    </a>
                    <a v-else :href="item.url" target="_blank" rel="noopener noreferrer">
                        <van-image class="avatar" :src="item.pic" />
                    </a>
                </van-swipe-item>
            </van-swipe>
            <van-notice-bar
                class="home-notice-bar"
                :left-icon="themeImgs.homeNoticeIcon"
                background="none"
                scrollable
                :text="announcementData || '暂无公告'"
            />
            <div class="balance-box" :style="`background:url(${themeImgs.homeBalanceBgImg})`">
                <span class="title">余额（{{store.state.publicConfig.title}}）</span>
                <span class="balance">{{memberDealInfo.currency_money || '0.00'}}</span>
            </div>
            <div class="enter-box">
                <router-link to="/tradingHall" class="enter-item-box">
                    <div class="left-box">
                        <span class="title">进入交易大厅</span>
                        <span class="title1">流程简单，快速成交</span>
                    </div>
                    <van-icon :name="themeImgs.homeDealIcon" />
                </router-link>
                <div class="enter-item-box" @click="handleOpenSellCoinUrl">
                    <div class="left-box">
                        <span class="title">我要抛售</span>
                        <span class="title1">流程简单，快速成交</span>
                    </div>
                    <van-icon :name="themeImgs.homeSellIcon" />
                </div>
            </div>
            <div class="wallet-address-box">
                <span class="label">钱包地址:</span>
                <span class="address">{{memberInfo.wallet_address}}</span>
                <van-icon :name="themeImgs.commonCopyIcon" @click="copyAddress(memberInfo.wallet_address)" />
            </div>
            <!--    比特币模块   start   -->
            <div class="bit-box">
                <div
                        class="bit-box-item"
                        @click="$router.push({ path: '/deposit', query: { type: 'recharge' }})">
                    <van-icon :name="themeImgs.homeBitRechargeIcon" />
                    <span class="item-text">USDT充值</span>
                    <van-icon name="arrow" />
                </div>
                <div
                        class="bit-box-item"
                        @click="$router.push({ path: '/deposit', query: { type: 'withdraw' }})">
                    <van-icon :name="themeImgs.homeBitWithdrawIcon" />
                    <span class="item-text">USDT提现</span>
                    <van-icon name="arrow" />
                </div>
            </div>
            <!--    比特币模块   end   -->

            <div class="amount-box">
                <div class="amount-item-box">
                    <van-icon :name="themeImgs.homeAmountIcon" />
                    <span class="amount">{{memberDealInfo.currency_money}}</span>
                    <span class="label">可售数量</span>
                </div>
                <div class="amount-item-box">
                    <van-icon :name="themeImgs.homeAmountIcon1" />
                    <span class="amount">{{memberDealInfo.sell_money}}</span>
                    <span class="label">卖单金额</span>
                </div>
                <div class="amount-item-box">
                    <van-icon :name="themeImgs.homeAmountIcon2" />
                    <span class="amount">{{memberDealInfo.trade_money}}</span>
                    <span class="label">交易中</span>
                </div>
            </div>

            <div v-if="isShowFromThirdPayBtn" :class="`${siteName}-back-pay-btn`" @click="goBackPayUrl">回到充值页</div>
            <!-- 弹窗 -->
            <van-dialog class="notification-pop" v-model:show="showNotification" :show-confirm-button="false">
                <div class="top-box">
                    <van-icon :name="themeImgs.homeCloseIcon" @click="handleCloseNotification" />
                </div>
                <van-swipe class="notification-swipe" indicator-color="#fff" ref="notificationSwipeRef">
                    <van-swipe-item v-for="(item,index) in notificationData" :key="index" :style="`backgroundImage:url(${themeImgs.homePopBgImg})`">
                        <div class="title">{{item.title}}</div>
                        <div class="content" v-html="handleContent(item.content)"></div>
                    </van-swipe-item>
                </van-swipe>
                <div class="btn-box" v-if="showPrevNextBtn">
                    <van-icon class="prev-btn" :name="themeImgs.homePrevIcon" @click="handlePrev" />
                    <van-icon class="next-btn" :name="themeImgs.homeNextIcon" @click="handleNext" />
                </div>
            </van-dialog>
            <div class="order-status-box" v-if="isHaveNewOrder" @click="openUrlOrderDetails">
                <p>您的订单有新状态</p>
                <div class="immediate-box">
                    <span>立即处理</span>
                    <van-icon :name="themeImgs.homeDoubleRightArrowIcon" />
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import { defineComponent, ref, onMounted, computed, watch } from "vue";
import { useRouter } from "vue-router";
import Footer from "@/components/Footer";
import { getMemberInfo,getBanner,getBulletin,getOrderRemind,getMemberAmount,logout } from '@/api/api'
import { useStore } from 'vuex'
import { Toast } from "vant";
import useClipboard from 'vue-clipboard3'
import wsConnection from "@/utils/websocket"
import { useGetAvatar} from "@hooks";
import { handleContent } from '@/utils'

export default defineComponent({
    components: {
        Footer
    },
    setup() {
        const $router = useRouter()
        const store = useStore()
        const { getAvatar } = useGetAvatar()
      // 盘口标识
        const siteName = ref(siteChannel)
        // 获取会员个人信息
        const handleGetMemberInfo = () => {
            getMemberInfo().then(res => {
                if(res.result == 200000) {
                    if(res.data.status != 1) {
                        memberInfo.value = res.data
                        store.dispatch('saveUserInfosActions', res.data)
                        // 为第三方跳转来的创建与登录接口返回值对应的对象
                        store.dispatch("saveLoginInfosActions", {...store.state.loginInfos,...{username:res.data.username,ws_url:res.data.ws_url}});
                    } else {
                        handleDoLogout()
                    }
                }
            })
        }
        // 退出登录
        const handleDoLogout = () => {
            logout().then(res => {
                if(res.result == 200000) {
                    Toast.success('账号被冻结')
                    localStorage.removeItem("loginInfos")
                    if(wsConnection.$ws) {
                        wsConnection.$ws.close()
                    }
                    $router.push('/login')
                }
            })
        }
        // 获取会员交易信息
        const handleGetMemberAmount = () => {
            getMemberAmount().then(res => {
                if(res.result == 200000) {
                    memberDealInfo.value = res.data.info
                    store.dispatch('saveMemberAmountActions', res.data.info)
                }
            })
        }
        // 获取banner
        const handleGetBanner = () => {
            getBanner().then(res => {
                if(res.result == 200000) {
                    bannerData.value = res.data.list
                }
            })
        }
        // 是否显示弹窗底部按钮
        const showPrevNextBtn = ref(false)
        // 获取滚动公告,弹窗，type1为弹窗，2为滚动
        const handleGetBulletin = () => {
            getBulletin({type:1}).then(res => {
                if(res.result == 200000) {
                    notificationData.value = res.data.list
                    if(res.data.list.length && !sessionStorage.getItem('CloseNotification')) {
                        showNotification.value = true
                    } else {
                        showNotification.value = false
                    }
                    if(res.data.list.length > 1) {
                        showPrevNextBtn.value = true
                    } else {
                        showPrevNextBtn.value = false
                    }
                }
            })
            getBulletin({type:2}).then(res => {
                if(res.result == 200000) {
                    if (res.data.list.length) {
                        res.data.list.forEach((item,index) => {
                            announcementData.value += `${item.content}  `
                        })
                    }
                }
            })
        }
        onMounted(() => {
            handleGetBanner()
            handleGetBulletin()
            handleGetMemberInfo()
            handleGetMemberAmount()
            handleGetOrderRemind()
            if(!!sessionStorage.getItem('fromThirdUrlStr')) {
                isShowFromThirdPayBtn.value = true
            }
        })
        // 存储banner数据
        const bannerData = ref([])
        // 存储滚动公告
        const announcementData = ref('')
        // 存储弹窗公告
        const notificationData = ref([])
        // 存储会员个人信息
        const memberInfo = ref({})
        // 存储会员单子信息
        const memberDealInfo = ref({})
        // 点击跳转到卖币页面
        const handleOpenSellCoinUrl = () => {
            if(memberInfo.value.is_name == 1) {
                return Toast.fail('请先进行实名认证')
            }
            if(memberInfo.value.is_name == 3) {
                return Toast.fail('实名审核中')
            }
            if(memberInfo.value.is_withdraw == 1) {
                return Toast.fail('该功能已禁用')
            }
            $router.push('/sellCoin')
        }
        // 点击跳转到站内消息
        const handleOpenMsgUrl = () => {
            $router.push('/announcement')
        }
        // 复制钱包地址
        const { toClipboard } = useClipboard()
        const copyAddress = async (content) => {
            try {
                await toClipboard(content)
                Toast.success('复制成功')
            } catch (e) {
                console.error(e)
            }
        }
        // 是否显示有无订单状态
        const isHaveNewOrder = ref(false)
        // 存储订单状态提醒数据
        const orderRemind = ref([])
        // 获取订单状态提醒
        const handleGetOrderRemind = () => {
          //    name === bitcoin,比特币消息，不需要调用接口
          if (orderRemindObj.value.name !== 'bitcoin') {
            getOrderRemind().then(res => {
              if(res.result == 200000) {
                orderRemind.value = res.data.list[0]
                if(res.data.list.length>0) {
                  isHaveNewOrder.value = true
                } else {
                  isHaveNewOrder.value = false
                }
              }
            })
          }
        }
        // 计算属性实现视图更新--新订单提醒
        const orderRemindObj = computed(() => store.state.orderRemind)
        watch(orderRemindObj, (newValue) =>{
            orderRemind.value = newValue
            isHaveNewOrder.value = true
        }, {
            deep: true // name是一个对象，需要进行深度监听
        })
        // 计算属性实现视图更新--金额数量变化
        const moneyChangeObj = computed(() => store.state.moneyChangeInfos)
        watch(moneyChangeObj, (newValue) =>{
            memberDealInfo.value = newValue
        }, {
            deep: true // name是一个对象，需要进行深度监听
        })
        // 计算属性实现视图更新--后台审核已实名
        const isName = computed(() => store.state.isName)
        watch(isName, (newValue) =>{
            handleGetMemberInfo()
        })
        // 点击跳转订单详情页
        const openUrlOrderDetails = () => {
            if (orderRemind.value.name !== 'bitcoin') {
              if(orderRemind.value.type==1) {
                $router.push({
                  path: 'buyerOrderDetails',
                  query: {
                    order_sn: orderRemind.value.order_sn
                  }
                })
              } else {
                $router.push({
                  path: 'sellerOrderDetails',
                  query: {
                    order_sn: orderRemind.value.order_sn
                  }
                })
              }
            } else {
              $router.push({
                path: '/order',
                query: {
                  bitType: orderRemind.value.type
                }
              })
            }
        }
        // 是否展示公告弹窗
        const showNotification = ref(false)
        // 关闭公告弹窗
        const handleCloseNotification = () => {
            showNotification.value = false
            sessionStorage.setItem('CloseNotification', true)
        }
        // 实例化
        const notificationSwipeRef = ref()
        // 切换到上一轮播
        const handlePrev = () => {
            notificationSwipeRef.value.prev();
        }
        // 切换到下一轮播
        const handleNext = () => {
            notificationSwipeRef.value.next();
        }
        // mapstate--皮肤对应的图片
        const themeImgs = computed(() => store.state.themeImgs)
        // 是否显示返回到充值页的按钮-第三方
        const isShowFromThirdPayBtn = ref(false)
        // 返回到充值页-第三方
        const goBackPayUrl = () => {
            $router.push(`/fromThirdPay?${sessionStorage.getItem('fromThirdUrlStr')}`)
            sessionStorage.removeItem('fromThirdUrlStr')
        }

      const setShowServicePopup = (flag) => {
        store.commit('setShowServicePopup', flag)
      }

      const goTobanner = (item) => {
        $router.push({
            path: "bannerDetail",
            query: {
                pic: item.url,
            },
        });
      }

        return {
            goTobanner,
            siteName,
            memberInfo,
            memberDealInfo,
            handleOpenMsgUrl,
            copyAddress,
            isHaveNewOrder,
            openUrlOrderDetails,
            handleOpenSellCoinUrl,
            store,
            bannerData,
            announcementData,
            showNotification,
            handleCloseNotification,
            showPrevNextBtn,
            notificationData,
            notificationSwipeRef,
            handlePrev,
            handleNext,
            themeImgs,
            goBackPayUrl,
            isShowFromThirdPayBtn,
            getAvatar,
            setShowServicePopup,
            handleContent
        };
    },
});
</script>

<style lang="less" scoped>
.home-wrap {
  height: 100%;
  padding: 0 15px;
    background: var(--mainBgColor);
    .home-main-wrap {
      height: calc(100% - 50px);
        overflow-y: auto;
        .home-header-wrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 15px 0;
            .avatar {
                width: 24px;
                height: 24px;
            }
            .logo {
                width: 120px;
            }
            .right-box {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .msg {
                    margin-left: 16px;
                }
            }
        }
        :deep(.my-swipe) {
            width: 100%;
            height: 130px;
            .van-image {
                width: 100%;
                .van-image__img {
                    width: 100%;
                    height: 130px;
                }
            }
        }
        .balance-box {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            height: 100px;
            padding: 15px 0 15px 20px;
            background-size: 100% 100% !important;
            box-shadow: 0px 3.47258px 4.34072px rgba(0, 0, 0, 0.6);
            border-radius: 13.0222px;
            .title {
                font-size: 14px;
                color: rgba(255, 255, 255, 0.8);
            }
            .balance {
                font-weight: 700;
                font-size: 34px;
                color: #fff;
            }
        }
        .enter-box {
            display: flex;
            justify-content: space-between;
            margin-top: 12px;
            .enter-item-box {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 166px;
                height: 52px;
                padding: 6px 14px;
                background: var(--boxBgColor);
                border-radius: 6px;
                color: var(--textCommonColor);
                box-shadow: var(--boxShadow);
                .left-box {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    .title {
                        font-weight: 500;
                        font-size: 17px;
                    }
                    .title1 {
                        font-weight: 400;
                        font-size: 11px;
                    }
                }
            }
        }
        .wallet-address-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 40px;
            margin-top: 12px;
            padding: 0 15px;
            background: var(--boxBgColor);
            border-radius: 8px;
            color: var(--textCommonColor);
            box-shadow: var(--boxShadow);
        }
        .amount-box {
            display: flex;
            justify-content: space-between;
            margin: 16px 0;
            .amount-item-box {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 110px;
                height: 116px;
                padding: 13px 0 13px 12px;
                background: var(--boxBgColor);
                border-radius: 10px;
                color: var(--textCommonColor);
                box-shadow: var(--boxShadow);
                .amount {
                    font-weight: 500;
                    font-size: 20px;
                }
                .label {
                    opacity: 0.8;
                }
            }
        }
        .bit-box {
            display: flex;
            justify-content: space-between;
            margin: 16px 0;
            .bit-box-item {
                width: 169px;
                height: 80px;
                flex-shrink: 0;
                border-radius: 10px;
                background: var(--boxBgColor);
                box-shadow: var(--boxShadow);
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                .item-text {
                    color: var(--textCommonColor);
                    text-align: center;
                    font-feature-settings: 'clig' off, 'liga' off;
                    font-family: PingFang SC;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
            }
        }
    }
/*    :deep(.line-pop){
      background: #ffffff;
      .top{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 57px;
        padding: 0px 14px;
        border-bottom: 8px solid #F8F9FA;
      }
      .manage{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 56px;
        border-bottom: 1px solid #F8F9FA;
        cursor: pointer;
      }
      .fill-img{
        width: 20px;
        margin-left: 5px;
        margin-top: 3px;
      }
      .fill-img img {
        width: 100%;
      }
      .cursor{
        cursor: pointer;
      }
    }*/
    :deep(.notification-pop) {
        top: 50%;
        width: 255px;
        background: none;
        .top-box {
            margin-bottom: -12px;
            text-align: right;
        }
        .notification-swipe {
            overflow: unset;
            .van-swipe-item {
                width: 100%;
                height: 361px;
                padding: 55px 15px 0;
                //background-size: 100% 100% !important;
                background-size: cover;
                .title {
                    font-weight: 500;
                    font-size: 16px;
                    color: #535C66;
                }
                .content {
                    height: 258px;
                    margin-top: 10px;
                    line-height: 22px;
                    color: rgba(83, 92, 102, 0.9);
                    word-break: break-all;
                    word-wrap: break-word;
                    overflow-y: scroll;
                }
                .van-swipe__indicators {
                    bottom: -100px;
                }
                .notification-swipe {
                    height: 100%;
                    .title {
                        font-weight: 500;
                        font-size: 16px;
                        color: #535C66;
                    }
                    .content {
                        margin-top: 10px;
                        line-height: 22px;
                        color: rgba(83, 92, 102, 0.9);
                        word-break: break-all;
                        word-wrap: break-word;
                    }
                    .van-swipe__indicators {
                        bottom: -100px;
                    }
                    .custom-indicator {
                        position: absolute;
                        right: 5px;
                        bottom: 5px;
                        padding: 2px 5px;
                        font-size: 12px;
                        background: rgba(0, 0, 0, 0.1);
                    }
                }
            }

          @media screen and (min-width: 750px) {
            .van-swipe-item {
              height: 200px;

              .content {
                height: 100px;
              }
            }
          }

            .van-swipe__indicators {
                bottom: -20px;
            }
        }
        .btn-box {
            padding-top: 40px;
            text-align: center;
            .next-btn {
                margin-left: 26px;
            }
        }
    }
    .order-status-box {
        position: fixed;
        right: 0;
        bottom: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 120px;
        height: 60px;
        padding-left: 10px;
        background: var(--btnBgColor);
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
        color: #fff;
        z-index: 1;
        p {
            font-size: 11px;
            font-weight: 500;
            text-align: center;
        }
        .immediate-box {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 8px;
            span {
                font-size: 10px;
                letter-spacing: 0.04em;
            }
            :deep(.van-icon) {
                .van-icon__image {
                    width: 8px;
                    height: 8px;
                }
            }
        }
    }
    .agpay-back-pay-btn {
        height: 44px;
        margin-bottom: 40px;
        line-height: 44px;
        text-align: center;
        background: linear-gradient(360deg, #AA8B68 0%, #E1CAAD 108.75%);
        border-radius: 6px;
        color: #fff;
    }
    .aipay-back-pay-btn {
        height: 44px;
        margin-bottom: 40px;
        line-height: 44px;
        text-align: center;
        background: linear-gradient(180deg, #144CB8 0%, #02318B 100%);
        border-radius: 6px;
        color: #fff;
    }
    .qtpay-back-pay-btn {
        height: 44px;
        margin-bottom: 40px;
        line-height: 44px;
        text-align: center;
        background: linear-gradient(180deg, #144CB8 0%, #02318B 100%);
        border-radius: 6px;
        color: #fff;
        font-weight: 700;
    }
}
</style>
