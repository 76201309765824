<template>
    <van-tabbar route :border="false" class="footer-wrap" :before-change="beforeChange">
        <van-tabbar-item to="/home">
            <span>首页</span>
            <template #icon="props">
                <img
                    :src="
                        props.active
                            ? themeImgs.footerHomeActiveIcon
                            : themeImgs.footerHomeIcon
                    "
                />
            </template>
        </van-tabbar-item>
        <van-tabbar-item to="/order" :badge="store.state.untreatedNum ? store.state.untreatedNum : ''">
            <span>订单</span>
            <template #icon="props">
                <img
                    :src="
                        props.active
                            ? themeImgs.footerOrderActiveIcon
                            : themeImgs.footerOrderIcon
                    "
                />
            </template>
        </van-tabbar-item>
<!--         <van-image v-if="showScan" round :src="themeImgs.footerScanIcon" @click="openScanCodeUrl" />-->
      <van-image v-if="showScan && siteName !== 'agpay'" round :src="themeImgs.footerScanIcon" @click="showScanActions = true" />
      <van-tabbar-item to="/payment">
            <span>收付款</span>
            <template #icon="props">
                <img
                    :src="
                        props.active
                            ? themeImgs.footerPaymentActiveIcon
                            : themeImgs.footerPaymentIcon
                    "
                />
            </template>
        </van-tabbar-item>
        <van-tabbar-item to="/my">
            <span>我的</span>
            <template #icon="props">
                <img
                    :src="
                        props.active
                            ? themeImgs.footerUserActiveIcon
                            : themeImgs.footerUserIcon
                    "
                />
            </template>
        </van-tabbar-item>
    </van-tabbar>

  <van-share-sheet
      v-model:show="showScanActions"
      title=""
      :options="options"
      @select="onSelect"
      class="scan-sheet"
  />
  <input @change="fileInputChange" type="file" id="qrcodeFileInput" accept="image/*">
  <div id="qrcodeResult" style="display: none;"></div>
</template>

<script>
import { defineComponent,ref,onMounted, computed, watch } from "vue"
import { Tabbar, TabbarItem, Toast } from "vant"
import { getMemberInfo } from '@/api/api'
import { useStore } from 'vuex'
import { useRouter } from "vue-router"
import { Html5Qrcode } from "html5-qrcode";

export default defineComponent({
    components: {
        [Tabbar.name]: Tabbar,
        [TabbarItem.name]: TabbarItem,
    },
    setup() {
      const siteName = ref(siteChannel)
      const store = useStore()
        const $router = useRouter()
        onMounted(()=>{
            // handleGetOrderRemind()
            judgeFromZH()
        })

/*        // 存储未处理订单的数量
        const UntreatedNum = ref()
        // 获取订单状态提醒
        const handleGetOrderRemind = () => {
            getOrderRemind().then(res => {
                if(res.result == 200000) {
                    if(res.data.count>0) {
                        UntreatedNum.value = res.data.count
                    } else {
                        UntreatedNum.value = ""
                    }
                }
            })
        }
        // 计算属性实现视图更新--新订单提醒
        const orderRemindObj = computed(() => store.state.orderRemind)
        watch(orderRemindObj, (newValue) =>{
            handleGetOrderRemind()
        }, {
            deep: true // name是一个对象，需要进行深度监听
        })*/

        // 点击扫码
        const openScanCodeUrl = () => {
            // 调用APP端的方法并传参给APP端
            if (window.Certification && window.Certification.scanQRCode) { // 安卓
                // 传参给Android
                try {
                    window.Certification.scanQRCode();
                } catch (error) {
                    console.log(error);
                }
            } else if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.scanQRCode) {
                // 传参给IOS
                try {
                    window.webkit.messageHandlers.scanQRCode.postMessage({});
                } catch (error) {
                    console.log(error);
                }
            }
        }
        const beforeChange = index => {
            if(index==2) {
                getMemberInfo().then(res => {
                    if(res.result == 200000) {
                        if(res.data.is_name == 1) {
                            return Toast.fail('请先进行实名认证')
                        } else if(res.data.is_name == 2) {
                            $router.push('/payment')
                        } else if(res.data.is_name == 3) {
                            return Toast.fail('实名审核中')
                        }
                    }
                })
            } else {
                return true
            }
        }
        // mapstate--皮肤对应的图片
        const themeImgs = computed(() => store.state.themeImgs)
        // 是否显示扫码---综合集成和第三方嵌套的，不显示， type 1是正常用户， 2是集成用户，2不显示
        const showScan = ref(true)
        const judgeFromZH = () => {
            if(window.parent.frames['agIframe'] || localStorage.getItem('isFromThird') || store.state.loginInfos.type === 2) {
                showScan.value = false
            } else {
                showScan.value = true
            }
        }

      const showScanActions = ref(false);
      const options = [
        { id: 'pic', name: '相册', icon: 'poster' },
        { id: 'scan', name: '扫一扫', icon: 'qrcode' },
      ];

      const onSelect = (option) => {
        showScanActions.value = false;

        if (option.id === 'scan') {
          $router.push('/scanPay')
        } else {
          store.commit('setIsScanSuccess', false)
          document.getElementById('qrcodeFileInput').click()
        }
      };

      const fileInputChange = (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const html5QrCode = new Html5Qrcode(/* element id */ 'qrcodeResult' );
        html5QrCode.scanFile(file, false)
            .then(decodedText => {
              if (decodedText.includes('order')) {
                $router.replace({ query: { data: JSON.stringify(decodedText) }} )
                store.commit('setIsScanSuccess', true)
              } else {
                Toast.fail(`请使用正确的二维码`)
              }
            })
            .catch(err => {
              // 未能解析到二维码
              Toast.fail(`无法解析二维码`)
              console.error('无法解析二维码', err)
            });
      }

      return {
            siteName,
            openScanCodeUrl,
            beforeChange,
            themeImgs,
            showScan,
            store,
            showScanActions,
            options,
            onSelect,
            fileInputChange
        };
    },
});
</script>

<style lang="less" scoped>
.footer-wrap {
    box-shadow: 0px -8px 20px rgba(29, 58, 88, 0.05);
    backdrop-filter: blur(27.1828px);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    .van-tabbar-item {
        padding: 7px 0;
        &:first-child {
            border-top-left-radius: 8px;
        }
        &:last-child {
            border-top-right-radius: 8px;
        }
        img {
            width: 24px;
            height: 24px;
        }
    }
    .van-tabbar-item__text {
        span {
            font-size: 10px;
            color: var(--unActiveItemTextColor);
        }
    }
    .van-tabbar-item--active {
        span {
            color: var(--activeItemTextColor);
        }
    }
    .van-image {
        width: 56px;
        height: 56px;
        margin-top: -25px;
    }
}
@media only screen and (min-width: 750px) {
    .footer-wrap {
        max-width: 375px;
        margin: 0 auto !important;
        left: auto;
    }
}

:global(.scan-sheet .van-share-sheet__options) {
  justify-content: space-evenly;
}
</style>
