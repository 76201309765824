export const handleContent = (text) => {
    return text.replace(/\n/g, '<br/>')
}

// 检测设备类型
export const detectOS = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // 检测 ios
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'ios';
    }

    // 检测 Android
    if (/android/i.test(userAgent)) {
        return 'android';
    }

    // 如果未检测到 Android 或 iOS，则返回未知
    return 'unknown';
}
